.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* Set the height and width for the map container */
#mapId {
  height: 100vh;
  width: 100vw;
}

.leaflet-popup-content-wrapper {
  padding: 0;
  background: #f9f9f9;
  border-radius: 5px;
  box-shadow: 0 1px 7px rgba(0, 0, 0, 0.2);
}

.leaflet-popup-content {
  margin: 10px;
  width: auto;
  height: auto;
}

.leaflet-popup-tip-container {
  display: none;
}

.leaflet-container {
  height: 100vh;
  width: 100%;
}

.form-group label {
  display: block; /* Display the form group label as a block */
  margin-bottom: 0.5rem; /* Add some margin between label and form element */
  font-weight: bold; /* Set the font-weight to bold */
  font-size: 1.1em; /* Increase the font size */
}

/* Add any other necessary styles for your application here */
